import React, { useState, useEffect } from 'react';
import mainPhoto from '../Images/mainglass.jpg';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row } from 'react-bootstrap';
import { Fade, Zoom } from 'react-reveal';
import TextTransition, { presets } from 'react-text-transition';

import TextContents from '../Components/TextContents';
import CardContents from '../Components/CardContents';

import cardPhoto1 from '../Images/glass.jpg';
import cardPhoto2 from '../Images/glass2.jpg';
import cardPhoto3 from '../Images/stainless.jpg';
import cardPhoto4 from '../Images/stainless2.jpg';

const TRANSITION_TEXT_TITLE = [
  'E3Procare',
  'Excellence',
  'Experience',
  'Expertise',
];
const TRANSITION_TEXT_WORDS = ['MONEY', 'TIME', 'ENVIRONMENT'];

const mainContent = [
  'We offer best solution for your scratch Glass repair and Interior damage repair.',
  ' You will be saving big money, time and the environment.',
  'Please check more pictures and short clips about our service.',
];

const Main = ({ isDesktop }) => {
  const [textIndex, setTextIndex] = useState(0);
  const [cardContents, setCardContents] = useState([
    {
      heading: 'Glass Scratch Repair',
      paragraphs: ['Glass Scratch?', 'Repair it!', "Don't Replace it!"],
      image: {
        src: cardPhoto1,
        alt: 'cardPhotoAlt',
      },
      image2: {
        src: cardPhoto2,
        alt: 'cardPhoto2Alt',
      },
      link: {
        title: 'Learn more',
        path: '/glass',
      },
      animation: true,
    },
    {
      heading: 'Interior Material Damage Repair',
      paragraphs: [
        <ul>
          <li>Bathtub</li>
          <li>Countertop</li>
          <li>Hardwood floor</li>
          <li>Cabinet</li>
          <li>Appliance</li>
          <li>Stainless</li>
          <li>Aluminum</li>
        </ul>,
      ],
      image: {
        src: cardPhoto3,
        alt: 'cardPhoto3Alt',
      },
      image2: {
        src: cardPhoto4,
        alt: 'cardPhoto4Alt',
      },
      link: {
        title: 'Learn more',
        path: '/interior',
      },
      animation: false,
    },
  ]);

  useEffect(() => {
    const intervalId = setInterval(
      () => setTextIndex((index) => index + 1),
      3000
    );
    return () => clearTimeout(intervalId);
  });

  return (
    <div>
      <div className="hero-main">
        <div className="background-picture">
          <picture>
            <Zoom duration={1000} distance="10%">
              <img src={mainPhoto} alt="E3Procare" />
            </Zoom>
          </picture>
        </div>

        <Container>
          <Row>
            <div className="col-10 offset-1">
              <div className="hero-main__foreground-container">
                <div className="hero-main__foreground-contents">
                  <Fade duration={1000} delay={200}>
                    <h1 className="hero-main__transition_title">
                      <TextTransition
                        text={
                          TRANSITION_TEXT_TITLE[
                            textIndex % TRANSITION_TEXT_TITLE.length
                          ]
                        }
                        springConfig={presets.slow}
                      />
                    </h1>
                    <div className="hero-main__heading1-container">
                      {/* <div className="hero-main__heading1-bg" /> */}
                      <h1>Glass Scratch&nbsp;</h1>
                      <h1>&amp;&nbsp;Interior Repairs</h1>
                    </div>
                  </Fade>
                  <Fade
                    left
                    cascade
                    duration={1000}
                    delay={200}
                    distance="200px"
                  >
                    <h3>{"Don't Replace,"}</h3>
                  </Fade>
                  <Fade
                    left
                    cascade
                    duration={1000}
                    delay={200}
                    distance="200px"
                  >
                    <h3>
                      <span>Save</span>
                      <span className="hero-main__transition">
                        <TextTransition
                          text={
                            TRANSITION_TEXT_WORDS[
                              textIndex % TRANSITION_TEXT_WORDS.length
                            ]
                          }
                          springConfig={presets.slow}
                        />
                      </span>
                    </h3>
                  </Fade>
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </div>

      <div className="main-contents__wrapper">
        <TextContents
          // heading={["We Offer"]}
          contents={mainContent}
          isDesktop={isDesktop}
          p_fontSize={'lg'}
        ></TextContents>

        <CardContents contents={cardContents} isDesktop={isDesktop} />
      </div>
    </div>
  );
};

export default Main;
