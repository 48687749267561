import React, { useEffect, useState } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Glass from "./Pages/Glass";
import Main from "./Pages/Main";
import Interior from "./Pages/Interior";
import About from "./Pages/About";
import Contact from "./Pages/Contact";
import Header from "./Components/Header";
import Footer from "./Components/Footer";

function App() {
  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    const updateWindowDimensions = () => {
      if (window.innerWidth > 769) {
        //769
        setIsDesktop(true);
      } else {
        setIsDesktop(false);
      }
    };

    updateWindowDimensions();

    window.addEventListener("resize", updateWindowDimensions);

    return () => window.removeEventListener("resize", updateWindowDimensions); //cleanup
  }, []);

  return (
    <BrowserRouter>
      <Header />
      <Switch>
        <Route path="/" exact>
          <Main isDesktop={isDesktop} />
        </Route>
        <Route path="/glass" exact>
          <Glass isDesktop={isDesktop} />
        </Route>
        <Route path="/interior" exact>
          <Interior isDesktop={isDesktop} />
        </Route>
        <Route path="/about" exact>
          <About isDesktop={isDesktop} />
        </Route>
        <Route path="/contact" exact>
          <Contact isDesktop={isDesktop} />
        </Route>
      </Switch>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
