import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookSquare,
  faYoutubeSquare,
} from "@fortawesome/free-brands-svg-icons";
import { faAngleUp } from "@fortawesome/free-solid-svg-icons";

import { Link } from "react-scroll";

const Footer = () => {
  return (
    <div className="footer">
      <Link to="header">
        <div className="back-to-top__container hoverY">
          <span className="back-to-top">
            <FontAwesomeIcon icon={faAngleUp} size="2x" />
          </span>
          <p>Back to top</p>
        </div>
      </Link>
      <div className="footer__icon-container">
        <a
          href="https://www.facebook.com/isaacseungjae.lee"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon
            icon={faFacebookSquare}
            size="4x"
            className="icons"
          />
        </a>
        <a
          href="https://www.youtube.com/channel/UCSd1wPP-u5o09rX24e9Z2mw"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon
            icon={faYoutubeSquare}
            size="4x"
            className="icons"
            color={"red"}
          />
        </a>
      </div>
      <small>Copyright &copy;2021 E3Procare. All Rights Reserved</small>
      <br />
      <br />
    </div>
  );
};

export default Footer;
