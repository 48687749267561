import React from 'react';
import { Container, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Hero from '../Components/Hero';
import TextContents from '../Components/TextContents';

import HeroImage from '../Images/hero_glass_office.jpg';

const About = ({ isDesktop }) => {
  const contents_about = [
    <ul>
      <li>We have excellently completed over 30 projects</li>
      <li>Over 15 years of experience</li>
      <li>Professional expertise</li>
      <li>Best Quality Guaranteed</li>
      {/* <li>1 million insured, Work Safe Alberta insured</li> */}
    </ul>,
  ];

  return (
    <>
      <Hero
        type="sub"
        title="About us"
        bgImgPath={HeroImage}
        isDesktop={isDesktop}
      />
      <TextContents
        heading={['Glass & Interior Repair Specialists']}
        contents={contents_about}
        isDesktop={isDesktop}
      >
        <h1>We service for:</h1>
        <ul>
          <li key={1}>New Construction Site</li>
          <li key={2}>Home Owner Service</li>
          <li key={3}>Building Management</li>
          <li key={4}>Hotel &amp; Shopping Mall</li>
        </ul>
        <div className="about__btn-container">
          <Container>
            <Link as={Link} to={'/contact'}>
              <Button variant="info">{'CONTACT'}</Button>
            </Link>
          </Container>
        </div>
      </TextContents>
    </>
  );
};

export default About;
