import React, { useState } from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import emailjs from 'emailjs-com';

const ContactForm = () => {
  const emailjsInfo = {
    SERVICE_ID: 'service_j7q609l',
    TEMPLATE_ID: 'template_fwfpz2a',
    USER_ID: 'user_ZaTsuPriZRd6DwUKK3UrF',
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        emailjsInfo.SERVICE_ID,
        emailjsInfo.TEMPLATE_ID,
        e.target,
        emailjsInfo.USER_ID
      )
      .then(
        (result) => {
          alert('Message successfully sent!');
        },
        (error) => {
          alert('Error sending message.');
        }
      );
  };

  return (
    <div className="contact__form-wrapper">
      <Form onSubmit={sendEmail}>
        <br />
        <Row>
          <h2 style={{ textAlign: 'center' }}>Contact Form</h2>
          <h5 style={{ textAlign: 'center' }}>
            Fill in the blanks to contact us!
          </h5>
        </Row>

        <br />

        <Row>
          <Col md={6} className="mb-3">
            <Form.Label className="mb-0">
              <h5>First Name</h5>
            </Form.Label>
            <Form.Control type="text" name="first_name" />
          </Col>
          <Col md={6} className="mb-3">
            <Form.Label className="mb-0">
              <h5>Last Name</h5>
            </Form.Label>
            <Form.Control type="text" name="last_name" />
          </Col>
        </Row>
        <Row>
          <Col md={6} className="mb-3">
            <Form.Label className="mb-0">
              <h5>Phone</h5>
            </Form.Label>
            <Form.Control type="text" placeholer="123-123-1234" name="phone" />
          </Col>
          <Col md={6} className="mb-3">
            <Form.Label className="mb-0">
              <h5>Email</h5>
            </Form.Label>
            <Form.Control
              type="email"
              placeholder="email@example.com"
              name="email"
            />
          </Col>
        </Row>
        <Row>
          <Col md={12} className="mb-3">
            <Form.Label className="mb-0">
              <h5>Company</h5>
            </Form.Label>
            <Form.Control type="text" name="company" />
          </Col>
        </Row>
        <Row>
          <Col md={12} className="mb-3">
            <Form.Label className="mb-0">
              <h5>Message</h5>
            </Form.Label>
            <Form.Control
              as="textarea"
              style={{ height: '80px' }}
              name="message"
            />
          </Col>
        </Row>
        <br />
        <Row className="contact__btn-row">
          <Button variant="info" type="submit">
            Send
          </Button>
        </Row>
      </Form>
    </div>
  );
};

export default ContactForm;
