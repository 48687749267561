import React from "react";
import { Container, Row } from "react-bootstrap";
import { Fade } from "react-reveal";

const TextContents = ({
  heading,
  contents,
  isDesktop,
  p_fontSize,
  children,
}) => {
  return (
    <div
      className={`text-contents ${
        isDesktop ? "text-contents-lg" : "text-contents-sm"
      }`}
    >
      <Container>
        <Row>
          <div className="text-contents__inner-container">
            <div className="col-10 col-lg-8 col-xl-6 offset-1 offset-lg-2 offset-xl-3">
              {heading &&
                heading.map((h) => {
                  return (
                    <div>
                      <Fade
                        left={isDesktop}
                        bottom={!isDesktop}
                        duration={1000}
                        delay={300}
                        distance="30px"
                      >
                        <h1>{h}</h1>
                      </Fade>
                    </div>
                  );
                })}

              {contents &&
                contents.map((content) => {
                  return (
                    <div>
                      <Fade
                        left={isDesktop}
                        bottom={!isDesktop}
                        duration={1000}
                        delay={500}
                        distance="30px"
                      >
                        <p className={p_fontSize === "lg" && "p-font-lg"}>
                          {content}
                        </p>
                      </Fade>
                    </div>
                  );
                })}

              <Fade
                left={isDesktop}
                bottom={!isDesktop}
                duration={1000}
                delay={500}
                distance="30px"
              >
                {children}
              </Fade>
            </div>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default TextContents;
