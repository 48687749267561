import React from 'react';
import { Card, Image } from 'react-bootstrap';

const ImageCard = ({ image }) => {
  return (
    <Card className="mt-3 mb-3">
      <Card.Header>{image.heading}</Card.Header>
      <Card.Body>
        <Image src={image.src} fluid className="d-block w-100" />
      </Card.Body>
    </Card>
  );
};

export default ImageCard;
