import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Hero from "../Components/Hero";
import TextContents from "../Components/TextContents";
import ImageCard from "../Components/ImageCard";

import glass from "../Images/glass.jpg";
import glex1 from "../Images/gl-ex1.jpg";
import glex2 from "../Images/gl-ex2.jpg";
import glex3 from "../Images/gl-ex3.jpg";
import glex4 from "../Images/gl-ex4.jpg";

function Glass({ isDesktop }) {
  const heading = ["Glass Scratch Repair"];

  const images = [
    { heading: "Window Scratch Repair", src: glex1 },
    { heading: "Window Scratch Repair", src: glex2 },
    { heading: "Glass Scratch Repair", src: glex3 },
    { heading: "Shower Booth Glass Repair", src: glex4 },
  ];

  const contents_glass = (
    <ul>
      <li>Specialized High-Tech Machinery</li>
      <li>
        <div className="word-wrap-container">
          <span>No Dust, No Distortion, </span>
          <span>100% clear</span>
        </div>
      </li>
      <li>
        <div className="word-wrap-container">
          <span>All Residential, </span>
          <span>Commercial Glass Repair</span>
        </div>
      </li>
      <li>
        <div className="word-wrap-container">
          <span>Glass Scratch, Graffiti, </span>
          <span>Welding Split Repair</span>
        </div>
      </li>
      <li>Over a thousand Glass Scratch Repair Experience</li>
    </ul>
  );

  return (
    <div>
      <Hero
        type="sub"
        title="Glass Restoration"
        bgImgPath={glass}
        isDesktop={isDesktop}
      />

      <div className="main-contents__wrapper">
        <TextContents
          heading={heading}
          contents={[contents_glass]}
          isDesktop={isDesktop}
        />
      </div>

      <Container>
        <Row>
          <Col xs={0} md={2}></Col>
          <Col xs={12} md={8}>
            <div className="video-responsive">
              <iframe
                src="https://www.youtube.com/embed/O0zfV3BLpAw"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </Col>
          <Col xs={0} md={2}></Col>
        </Row>
        <Row></Row>
      </Container>

      <br />

      <div className="main-contents__wrapper">
        <Container>
          <Row xs={1} md={2}>
            {images.map((image, i) => {
              return (
                <Col>
                  <ImageCard image={image} />
                </Col>
              );
            })}
          </Row>
        </Container>
      </div>
    </div>
  );
}
export default Glass;
