import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Hero from '../Components/Hero';
import TextContents from '../Components/TextContents';
import CarouselCard from '../Components/CarouselCard';

import stainless from '../Images/stainless.jpg';

import bathtubbefore from '../Images/bathtubbefore.jpg';
import bathtubafter from '../Images/bathtubafter.jpg';
import bathtubmiddle from '../Images/bathtubmiddle.jpg';
import cabinetdoorbefore from '../Images/cabinetdoorbefore.jpg';
import cabinetdoorafter from '../Images/cabinetdoorafter.jpg';
import cabinetdoormiddle from '../Images/cabinetdoormiddle.jpg';
import doorscratchbefore from '../Images/doorscratchbefore.jpg';
import doorscratchafter from '../Images/doorscratchafter.jpg';
import doorscratchmiddle from '../Images/doorscratchmiddle.jpg';
import marblebefore from '../Images/marblebefore.jpg';
import marbleafter from '../Images/marbleafter.jpg';
import marblemiddle from '../Images/marblemiddle.jpg';
import showerbasebefore from '../Images/showerbasebefore.jpg';
import showerbaseafter from '../Images/showerbaseafter.jpg';
import showerbasemiddle from '../Images/showerbasemiddle.jpg';
import slidingbefore from '../Images/slidingbefore.jpg';
import slidingafter from '../Images/slidingafter.jpg';
import slidingmiddle from '../Images/slidingmiddle.jpg';
import tilebefore from '../Images/tilebefore.jpg';
import tileafter from '../Images/tileafter.jpg';
import tilemiddle from '../Images/tilemiddle.jpg';
import tileovercutbefore from '../Images/tileovercutbefore.jpg';
import tileovercutafter from '../Images/tileovercutafter.jpg';
import tileovercutmiddle from '../Images/tileovercutmiddle.jpg';
import woodfloorbefore from '../Images/woodfloorbefore.jpg';
import woodfloorafter from '../Images/woodfloorafter.jpg';
import woodfloormiddle from '../Images/woodfloormiddle.jpg';
import washerbefore from '../Images/washerbefore.jpg';
import washerafter from '../Images/washerafter.jpg';
import washermiddle from '../Images/washermiddle.jpg';

const Interior = ({ isDesktop }) => {
  const heading = ['Interior Material Chip, Scratch, Damage Repair'];

  const contents_interior = (
    <ul>
      <li>Bathtub, Shower Pan</li>
      <li>Countertop</li>
      <ul>
        <li>Marble</li>
        <li>Quartz</li>
        <li>Granite</li>
      </ul>
      <li>Hardwood Floor</li>
      <li>Tile</li>
      <li>Cabinet, Entry Door, Furniture</li>
      <li>Appliances Dent, Scratch</li>
      <li>Stainless Appliance</li>
      <li>Kitchen Sink</li>
      <li>Aluminum</li>
    </ul>
  );

  return (
    <>
      <Hero
        type="sub"
        title="Interior Repairs"
        bgImgPath={stainless}
        isDesktop={isDesktop}
      />
      <div className="main-contents__wrapper">
        <TextContents
          heading={heading}
          contents={[contents_interior]}
          isDesktop={isDesktop}
        />

        <Container>
          <Row xs={1} md={2}>
            <Col>
              <CarouselCard
                heading="Bath tub chip"
                imgBefore={bathtubbefore}
                imgAfter={bathtubafter}
                imgMiddle={bathtubmiddle}
              />
            </Col>
            <Col>
              <CarouselCard
                heading="Shower pan chip"
                imgBefore={showerbasebefore}
                imgAfter={showerbaseafter}
                imgMiddle={showerbasemiddle}
              />
            </Col>
            <Col>
              <CarouselCard
                heading="Marble chip"
                imgBefore={marblebefore}
                imgAfter={marbleafter}
                imgMiddle={marblemiddle}
              />
            </Col>
            <Col>
              <CarouselCard
                heading="Wood floor damage"
                imgBefore={woodfloorbefore}
                imgAfter={woodfloorafter}
                imgMiddle={woodfloormiddle}
              />
            </Col>
            <Col>
              <CarouselCard
                heading="Tile damage"
                imgBefore={tilebefore}
                imgAfter={tileafter}
                imgMiddle={tilemiddle}
              />
            </Col>
            <Col>
              <CarouselCard
                heading="Tile overcut"
                imgBefore={tileovercutbefore}
                imgAfter={tileovercutafter}
                imgMiddle={tileovercutmiddle}
              />
            </Col>
            <Col>
              <CarouselCard
                heading="Cabinet door damage"
                imgBefore={cabinetdoorbefore}
                imgAfter={cabinetdoorafter}
                imgMiddle={cabinetdoormiddle}
              />
            </Col>
            <Col>
              <CarouselCard
                heading="Door damage"
                imgBefore={doorscratchbefore}
                imgAfter={doorscratchafter}
                imgMiddle={doorscratchmiddle}
              />
            </Col>
            <Col>
              <CarouselCard
                heading="Washer damage"
                imgBefore={washerbefore}
                imgAfter={washerafter}
                imgMiddle={washermiddle}
              />
            </Col>
            <Col>
              <CarouselCard
                heading="Sliding track bent"
                imgBefore={slidingbefore}
                imgAfter={slidingafter}
                imgMiddle={slidingmiddle}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Interior;
