import React from "react";
import { Container } from "react-bootstrap";

const ContactInfo = () => {
  return (
    <div>
      <br />
      <Container>
        <h2>HOURS</h2>
        <h4>Monday-Friday</h4>
        <h4>7:00 AM - 4:00 PM MDT</h4>

        <br />

        <h2>Address</h2>
        <h4>196 Everhollow ST SW</h4>
        <h4>Calgary, AB</h4>
        <h4>Canada</h4>
        <h4>T2Y 0H3</h4>

        <br />

        <h2>PHONE</h2>
        <h4>
          <a href="tel:4038010931">403-801-0931</a>
        </h4>
        <h4>(Text Preferred)</h4>
        <br />

        <h2>EMAIL</h2>
        <h4>
          <a href="mailto:e3procare@gmail.com">e3procare@gmail.com</a>
        </h4>
      </Container>
    </div>
  );
};

export default ContactInfo;
