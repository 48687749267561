import React from "react";
import { Card, Carousel, Image } from "react-bootstrap";

const CarouselCard = ({ heading, imgBefore, imgAfter, imgMiddle }) => {
  return (
    <Card className="mt-3 mb-3">
      <Card.Header>{heading}</Card.Header>
      <Card.Body>
        <Carousel>
          <Carousel.Item>
            <Image src={imgBefore} fluid className="d-block w-100" />
            <Carousel.Caption>
              <h5>Before</h5>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <Image src={imgMiddle} fluid className="d-block w-100" />
            <Carousel.Caption>
              <h5>In progress</h5>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <Image src={imgAfter} fluid className="d-block w-100" />
            <Carousel.Caption>
              <h5>After</h5>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </Card.Body>
    </Card>
  );
};

export default CarouselCard;
