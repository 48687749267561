import React, { useEffect, useState } from 'react';
import { Container, Row, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Fade } from 'react-reveal';

import TextTransition, { presets } from 'react-text-transition';

const TextSlide = ({ paragraphs }) => {
  const [textIndex, setTextIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(
      () => setTextIndex((index) => index + 1),
      2000
    );
    return () => clearTimeout(intervalId);
  });

  return (
    <div className="mb-3 card-contents__textslide">
      <TextTransition
        text={paragraphs[textIndex % paragraphs.length]}
        springConfig={presets.slow}
      />
    </div>
  );
};

export default TextSlide;
