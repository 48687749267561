import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Hero from "../Components/Hero";

import ContactInfo from "../Components/ContactInfo";
import ContactForm from "../Components/ContactForm";

import contactPhoto from "../Images/contact.jpg";

const Contact = ({ isDesktop }) => {
  return (
    <div>
      <Hero
        type="sub"
        title="Contact Us"
        bgImgPath={contactPhoto}
        isDesktop={isDesktop}
      />
      <br />
      <Container>
        <Row>
          <Col lg={4}>
            <ContactInfo />
          </Col>
          <Col lg={8}>
            <ContactForm />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Contact;
