import { Fade } from "react-reveal";

const FadeImage = ({ src, alt }) => {
  return (
    <Fade duration={3000}>
      <img src={src} alt={alt} />
    </Fade>
  );
};

export default FadeImage;
