import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Nav, Navbar, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Header = () => {
  const [isXL, setIsXL] = useState(false);

  useEffect(() => {
    const updateWindowDimensions = () => {
      if (window.innerWidth > 1200) {
        setIsXL(true);
      } else {
        setIsXL(false);
      }
    };

    updateWindowDimensions();

    window.addEventListener('resize', updateWindowDimensions);

    return () => window.removeEventListener('resize', updateWindowDimensions); //cleanup
  }, []);

  return (
    <div id="header">
      {/* <Container> */}
      <Navbar collapseOnSelect expand="xl" className="navbar">
        <Container>
          <Navbar.Brand as={Link} to={'/'}>
            <h1 className="brand">
              <span className="brandblue">E3</span>Procare
            </h1>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ms-auto me-3">
              <Nav.Link as={Link} to="/glass" eventKey="1">
                <h4 className="hoverY">Glass Restoration</h4>
              </Nav.Link>
              <Nav.Link as={Link} to="/interior" eventKey="2">
                <h4 className="hoverY">Interior Repairs</h4>
              </Nav.Link>
              <Nav.Link as={Link} to="/about" eventKey="3">
                <h4 className="hoverY">About us</h4>
              </Nav.Link>
              {isXL ? (
                <></>
              ) : (
                <Nav.Link as={Link} to="/contact" eventKey="4">
                  <h4 className="hoverY">Contact us</h4>
                </Nav.Link>
              )}
            </Nav>
            <Nav>
              {isXL ? (
                <Nav.Link as={Link} to="/contact" eventKey="4">
                  <Button variant="info">Contact Us</Button>
                </Nav.Link>
              ) : (
                <></>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {/* </Container> */}
    </div>
  );
};

export default Header;
