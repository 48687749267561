import React, { useEffect, useState } from 'react';
import { Container, Row, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Fade } from 'react-reveal';

import TextTransition, { presets } from 'react-text-transition';
import TextSlide from './TextSlide';

import FadeImage from './FadeImage';

import defaultPhoto from '../Images/mainglass.jpg';

const CardContents = ({ contents, isDesktop }) => {
  let reverseFg = false;

  const [cardContents, setCardContents] = useState(contents);
  const [textIndex, setTextIndex] = useState(0);
  const TRANSITION_TEXT_WORDS = [];

  useEffect(() => {
    const interval = setInterval(() => {
      setCardContents(
        cardContents.map((content) => {
          const temp = content.image.src;
          content.image.src = content.image2.src;
          content.image2.src = temp;
          return { ...content };
        })
      );
    }, 8000);

    return () => clearInterval(interval);
  }, [cardContents]);

  useEffect(() => {
    const intervalId = setInterval(
      () => setTextIndex((index) => index + 1),
      3000
    );
    return () => clearTimeout(intervalId);
  });

  return (
    <div className="card-contents">
      {contents.map((content, i) => {
        reverseFg = !reverseFg;

        return (
          <div key={i}>
            <Container fluid>
              <Row className={`no-gutters ${!reverseFg && 'row-reverse'}`}>
                <div className="col-12 col-lg-6">
                  <div className="card-contents__img-container">
                    <picture>
                      <FadeImage
                        key={content.image.src}
                        src={content.image.src || defaultPhoto}
                        alt={content.image.alt}
                      />
                      {/* <Fade duration={1000} delay={200} distance="200px">
                      <img
                        src={content.image.src || defaultPhoto}
                        alt={content.image.alt}
                      />
                    </Fade> */}
                    </picture>
                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  <Row className="card-contents__inner-row">
                    <div className="card-contents__inner-container col-10 col-md-8 offset-1 offset-md-2">
                      <Fade
                        left={isDesktop}
                        bottom={!isDesktop}
                        duration={1000}
                        delay={200}
                        distance="50px"
                      >
                        <h1>{content.heading}</h1>
                      </Fade>
                      {content.animation ? (
                        <TextSlide paragraphs={content.paragraphs} />
                      ) : (
                        content.paragraphs.map((para) => {
                          return (
                            <Fade
                              left={isDesktop}
                              bottom={!isDesktop}
                              duration={1000}
                              delay={300}
                              distance="50px"
                            >
                              <p>{para}</p>
                            </Fade>
                          );
                        })
                      )}

                      <Fade
                        left={isDesktop}
                        bottom={!isDesktop}
                        duration={1000}
                        delay={400}
                        distance="50px"
                      >
                        <Container className="card-contents__inner-btn-container">
                          <Link as={Link} to={content.link.path}>
                            <Button variant="info">{content.link.title}</Button>
                          </Link>
                        </Container>
                      </Fade>
                    </div>
                  </Row>
                </div>
              </Row>
            </Container>
          </div>
        );
      })}
    </div>
    // <div className="card-contents">
    //   <Container fluid>
    //     <Row className="no-gutters">
    //       <div className="col-12 col-lg-6">
    //         <div className="card-contents__img-container">
    //           <picture>
    //             <img
    //               src={contents[0].image.src || defaultPhoto}
    //               alt={contents[0].image.alt}
    //             />
    //           </picture>
    //         </div>
    //       </div>
    //       <div className="col-12 col-lg-6">
    //         <Row className="card-contents__inner-row">
    //           <div className="card-contents__inner-container col-10 col-md-8 offset-1 offset-md-2">
    //             <h1>{contents[0].heading}</h1>
    //             {contents[0].paragraphs.map((p) => {
    //               return <p>{p}</p>;
    //             })}
    //             <Link as={Link} to={contents[0].link.path}>
    //               <Button variant="info">{contents[0].link.title}</Button>
    //             </Link>
    //           </div>
    //         </Row>
    //       </div>
    //     </Row>
    //   </Container>
    //   {/* <div
    //     className={`text-contents ${
    //       isDesktop ? "text-contents-lg" : "text-contents-sm"
    //     }`}
    //   >
    //     <Container>
    //       <Row>
    //         <div className="text-contents__inner-container">
    //           <div className="col-10 col-lg-8 col-xl-6 offset-1 offset-lg-2 offset-xl-3">
    //             <h1>TestHeading</h1>
    //             {contents.map((content) => {
    //               return <p>{content}</p>;
    //             })}
    //           </div>
    //         </div>
    //       </Row>
    //     </Container>
    //   </div> */}
    // </div>
  );
};

export default CardContents;
