import React from "react";
import { Container, Row } from "react-bootstrap";
import { Fade } from "react-reveal";

import defaultPhoto from "../Images/mainglass.jpg";

const Hero = ({ type, title, bgImgPath, isDesktop }) => {
  return (
    <div className={`hero-sub ${isDesktop ? "hero-sub-lg" : "hero-sub-sm"}`}>
      <div className="background-picture">
        <picture>
          <Fade duration={1500} distance="10%">
            <img src={bgImgPath || defaultPhoto} alt="Background" />
          </Fade>
        </picture>
      </div>

      <Container>
        <Row>
          <div className="col-10 offset-1 offset-lg-2">
            <div className="hero-sub__foreground-container">
              <div className="hero-sub__foreground-contents">
                <Fade left cascade duration={1000} delay={200} distance="200px">
                  <h1>{title}</h1>
                </Fade>
              </div>
            </div>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default Hero;
